import {
  startBugsnag,
  isBrowserSupported,
  defineInitFunction,
} from '../../common/init';
import {defineElement as defineShopLoginButtonElement} from '../../components/loginButton/shop-login-button';
import {defineElement as defineShopLoginDefaultElement} from '../../components/loginButton/shop-login-default';

import {initCustomerAccountsSignUp} from './initCustomerAccountsSignUp';

/**
 * Initialize the initCustomerAccountsSignUp function.
 */
function init() {
  if (!isBrowserSupported()) return;
  startBugsnag({
    bundle: 'initCustomerAccountsSignUp',
    // eslint-disable-next-line no-process-env
    bundleLocale: process.env.BUILD_LOCALE,
  });

  defineShopLoginButtonElement();
  defineShopLoginDefaultElement();
  defineInitFunction('initCustomerAccountsSignUp', initCustomerAccountsSignUp);
}

init();
